const Instagram = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-label="[title + description]"
  >
    <title>Instagram Icon</title>
    <desc>Headout&apos;s Instagram Handle</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.03809 1.16667C9.49856 1.16667 10.6457 1.16666 11.5413 1.28707C12.459 1.41046 13.1872 1.66848 13.7594 2.24061C14.3315 2.81275 14.5895 3.54092 14.7129 4.45869C14.8333 5.3543 14.8333 6.5014 14.8333 7.96187V8.03814C14.8333 9.49861 14.8333 10.6457 14.7129 11.5413C14.5895 12.4591 14.3315 13.1873 13.7594 13.7594C13.1872 14.3315 12.459 14.5895 11.5413 14.7129C10.6457 14.8333 9.49856 14.8333 8.03809 14.8333H7.96183C6.50136 14.8333 5.35425 14.8333 4.45864 14.7129C3.54087 14.5895 2.8127 14.3315 2.24057 13.7594C1.66844 13.1873 1.41041 12.4591 1.28702 11.5413C1.16661 10.6457 1.16662 9.49861 1.16663 8.03814V7.96187C1.16662 6.50142 1.16661 5.3543 1.28702 4.45869C1.41041 3.54092 1.66844 2.81275 2.24057 2.24061C2.8127 1.66848 3.54087 1.41046 4.45864 1.28707C5.35425 1.16666 6.50136 1.16667 7.96183 1.16667H8.03809ZM11 8.00001C11 9.65687 9.65683 11 7.99996 11C6.34311 11 4.99996 9.65687 4.99996 8.00001C4.99996 6.34315 6.34311 5.00001 7.99996 5.00001C9.65683 5.00001 11 6.34315 11 8.00001ZM11.6748 5.00001C12.0414 5.00001 12.3385 4.70153 12.3385 4.33334C12.3385 3.96515 12.0414 3.66667 11.6748 3.66667H11.6688C11.3023 3.66667 11.0052 3.96515 11.0052 4.33334C11.0052 4.70153 11.3023 5.00001 11.6688 5.00001H11.6748Z"
      fill="currentColor"
    />
  </svg>
);
export default Instagram;
